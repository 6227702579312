import { render, staticRenderFns } from "./Parameters.vue?vue&type=template&id=36a991ff&scoped=true"
import script from "./Parameters.vue?vue&type=script&lang=js"
export * from "./Parameters.vue?vue&type=script&lang=js"
import style0 from "./Parameters.vue?vue&type=style&index=0&id=36a991ff&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36a991ff",
  null
  
)

export default component.exports