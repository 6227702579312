<template>
  <div class="parameters">
    <PageTitle title="參數設置" hideBtn style="margin-bottom: 64px" />
    <DevOnly style="margin-bottom: 30px">
      <SearchTextInput :value.sync="search" placeholder="輸入搜尋" autofocus />
    </DevOnly>
    <div class="parameters-category-list">
      <CategoryBlock
        v-for="param in paramsRoutes"
        :key="param.name"
        :data="param"
      />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle'
import CategoryBlock from './components/CategoryBlock'
import { filter, get, includes, lowerCase } from 'lodash'
import { computed, defineComponent, ref } from 'vue'
import store from '@/store'
import SearchTextInput from '@/components/Input/SearchTextInput.vue'

export default defineComponent({
  name: 'Parameters',
  components: {
    CategoryBlock,
    PageTitle,
    SearchTextInput,
  },
  setup () {
    const search = ref('')
    const allRoutes = computed(() => get(store.state, 'permission.routes'))
    const paramsRoutes = computed(() => {
      if (allRoutes.value) {
        if (!allRoutes.value.length) return []
      }
      const routes = allRoutes.value.filter(route => route.name === 'Parameters')
      if (routes) {
        if (!routes.length) return []
      }
      const children = routes[0].children
      return filter(children, (route) => {
        if (route.hidden) return false
        if (search.value) {
          return includes(get(route, 'meta.title'), search.value) || includes(lowerCase(get(route, 'name')), lowerCase(search.value))
        }
        return route
      })
    })

    return {
      allRoutes,
      paramsRoutes,
      search,
    }
  },
})
</script>

<style lang="postcss" scoped>
.parameters-category-list {
  @apply grid grid-cols-3 gap-[44px];
}

@media screen and (max-width: 1200px) {
  .parameters-category-list {
    @apply grid grid-cols-2;
  }
}
</style>
